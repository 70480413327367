<template>
  <batch-dialog
    :visible="visible"
    @close="closeAction"
    @cancel="cancelAction"
    @onOk="onOk"
    @onDialogOpened="onDialogOpened"
    :record-operation="operation"
    :record-list="recordList"
    :is-selected-record="isSelectedRecord"
    :batch-operation-status="batchOperationStatus"
    :alert="internalAlert"
    :progress="progressText"
  >
    <template v-slot:moreContext>
      <v-divider class="mt-3 mb-2"></v-divider>

      <!-- Download file as option -->
      <v-switch
        :prepend-icon="downloadAsPdfIcon"
        v-model="isDownloadAsPdf"
        v-show="!hideSwitch"
        :label="downloadAsPdfLabel"
      ></v-switch>

      <!-- Download Annotation/Redaction options -->
      <download-annotation-option
        :annotate-option="annotateOption"
        :manage-annotations="isAllowedAnnotate"
        :manageRedactions="isAllowedRedact"
        v-if="visibleAnnotationOption"
      >
      </download-annotation-option>
    </template>
  </batch-dialog>
</template>

<script>
// services
import {
  fullRecordName,
  isAllowedOperation,
  IsCheckedOutForDigitalSigningByPrinciple,
  isCompoundDocument,
  localFileFirstOrDefaultExtension
} from "@/services/record/recordService";

// model
import {
  batchRecordStatus,
  findRecordOperation,
  recordOperation
} from "@/model/record/recordModel";
import { fieldDownloadType } from "@/model/document/documentModel";
import {
  downloadFileMode,
  extensionName,
  findExtension,
  isAnnotateFileTypeExtension,
  isPdfExtension
} from "@/model/record/fileModel";
import { actionResultType } from "@/model/action/actionModel";

// mixins
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";
import { downloadableMixin } from "@/mixins/shared/downloadable/downloadableMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { emailFiles } from "@/services/dom/fileSystemAccessService";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";
import { batchDialogMixin } from "@/mixins/shared/batch/batchDialogMixin";

export default {
  name: "BatchDownloadRecordsDialog",
  components: {
    BatchDialog: () => import("@/components/shared/core/dialogs/BatchDialog")
  },
  mixins: [
    annotationOptionMixin,
    downloadableMixin,
    progressAbleMixin,
    recordBannerAble,
    recordIconMixin,
    alertableMixin,
    batchDialogMixin
  ],
  data() {
    return {
      isDownloadAsPdf: false,
      downloadFileMode: downloadFileMode,
      isSelectedRecord: null,
      fullRecordName: fullRecordName,
      isCanceled: false,
      batchOperationStatus: []
    };
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    recordList: undefined,
    downloadMode: {
      type: Number,
      default: downloadFileMode.default
    },
    isEmail: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return this.isEmail
        ? findRecordOperation(recordOperation.Email)
        : findRecordOperation(recordOperation.Download);
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operation?.label}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * determines whether current user is Allowed to Annotate current image
     * @return {Boolean|boolean} return true if current user is allowed to Annotate current image
     */
    isAllowedAnnotate() {
      return this.isAllowedOperation(recordOperation.RunImageAnnotation);
    },

    /**
     * determines whether current user is Allowed to Redact current image
     * @return {Boolean|boolean} return true if current user is allowed to Redact current image
     */
    isAllowedRedact() {
      return this.isAllowedOperation(recordOperation.RunImageRedaction);
    },

    /**
     * compute download As Pdf Label
     * @return {string} download As Pdf Label
     */
    downloadAsPdfLabel() {
      return `As ${findExtension(extensionName.pdf)?.acronym}`;
    },

    /**
     * download As Pdf Icon name
     * @return {string} download As Pdf Icon name
     */
    downloadAsPdfIcon() {
      return findExtension(extensionName.pdf)?.icon;
    },

    /**
     * Is WIP File
     * @return {boolean}
     */
    isWip() {
      return this.downloadMode === downloadFileMode.wip;
    },

    /**
     * visible Annotation Option
     * @return {boolean}
     */
    visibleAnnotationOption() {
      if (this.isWip) {
        return true;
      }

      if (this.isPdf) {
        return false;
      }

      for (const record of this.recordList) {
        if (!isAnnotateFileTypeExtension(record?.extension)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Check if any of the records in the list is a PDF. If any record is a PDF, no need to show download as PDF switch.
     * @return {boolean}
     */
    isPdf() {
      if (this.isWip) {
        // If isWip is true, check each record's extension
        return this.recordList.some(record =>
          isPdfExtension(localFileFirstOrDefaultExtension(record))
        );
      } else {
        // If isWip is false, check each record's extension or checkOutExtension based on other conditions
        return this.recordList.some(record => {
          if (
            IsCheckedOutForDigitalSigningByPrinciple(record, this.principal)
          ) {
            return isPdfExtension(record?.checkOutExtension);
          } else {
            return isPdfExtension(record?.extension);
          }
        });
      }
    },

    /**
     * Check if record is Compound Document
     * @return {boolean} true if provided record is Compound document
     */
    isCompound() {
      for (const record of this.recordList) {
        if (!isCompoundDocument(record)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Check if pdf switch should be hidden
     * @return {boolean}
     */
    hideSwitch() {
      return this.isPdf || this.isCompound;
    },

    /**
     * Is View File Mode (File History)
     * @return {boolean}
     */
    isViewFileMode() {
      return this.downloadMode === downloadFileMode.view;
    }
  },
  methods: {
    /**
     * Download Progress Text
     * @param record
     * @param index
     * @return {string}
     */
    downloadProgressText(record, index) {
      return `Downloading Record ${record.name} - ${index} of ${this.recordList.length}  `;
    },

    async onOk() {
      try {
        let fileBlobs = [];
        for (let i = 0; i < this.recordList.length; i++) {
          if (!this.isCanceled) {
            const record = this.recordList[i];
            this.isSelectedRecord = record;
            this.clearAlert();
            this.progressText = this.downloadProgressText(record, i + 1);
            /*this.showProgress(
              this.formatProgressText(this.downloadProgressText(record, i + 1))
            );*/

            const downloadType = this.isDownloadAsPdf
              ? fieldDownloadType.asPdf
                ? isPdfExtension(record.checkOutExtension)
                : fieldDownloadType.asPdf
              : fieldDownloadType.asItIs;

            const downloadFileOption = {
              includeAnnotations: this.visibleAnnotationOption
                ? this.annotateOption.includeAnnotations
                : false,
              includeRedaction: this.visibleAnnotationOption
                ? this.annotateOption.includeRedaction
                : false,
              burnIntoImage: this.visibleAnnotationOption
                ? this.annotateOption.burnIntoImage
                : false,
              burnRedaction: this.visibleAnnotationOption
                ? this.annotateOption.burnRedaction
                : false,
              maintainColor: this.visibleAnnotationOption
                ? this.annotateOption.maintainColor
                : false
            };

            /**
             * TODO: use downloadFileOption sa an arg in: this.onDownloadFile()
             */
            let result = await this.onDownloadFile(
              record,
              downloadType,
              downloadFileOption,
              this.downloadMode,
              this.isEmail,
              true
            );

            if (this.isEmail) {
              const fileBlob = {
                blob: result?.blob,
                fileName: result?.fileName
              };
              if (fileBlob.blob) fileBlobs.push(fileBlob);
              result = result?.result;
            }

            if (result?.type === actionResultType.success) {
              this.setStatus(record.id, batchRecordStatus.Completed, "Success");
              //await this.autoCloseDialog(result.message);
            } else if (result?.type === actionResultType.abort) {
              this.setStatus(record.id, batchRecordStatus.Canceled, "Canceled");
              //this.closeDialog();
            } else {
              this.setStatus(
                record.id,
                batchRecordStatus.Failed,
                result.message
              );
              this.internalAlert = this.createAlert(
                this.alertTypeName.error,
                this.formatAlertError(this.action, result.message),
                false
              );
            }
          }
        }

        if (this.isEmail) await emailFiles(fileBlobs);

        this.internalAlert = this.createAlertSuccess(
          `${this.action} Operation Completed`,
          false
        );
      } catch (e) {
        this.closeProgress();
        this.isCanceled = true;
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.isCanceled = false;
        this.closeProgress();
        //this.closeDialog();
        this.isSelectedRecord = undefined;
      }
    },

    /**
     * is Allowed Operation
     * @param operation operation name
     * @return {Boolean|boolean} true if Operation is Allowed
     */
    isAllowedOperation(operation) {
      for (const record of this.recordList) {
        if (!isAllowedOperation(record, operation)) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
